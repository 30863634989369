

























































































import { Component, Vue } from 'vue-property-decorator'
import LanguageSwitcher from './LanguageSwitcher.vue'
import { vxm } from '@/store/store.vuex'

@Component({
  components: { LanguageSwitcher }
})
export default class Navbar extends Vue {
  logoSrc =
    'https://firebasestorage.googleapis.com/v0/b/photos-portfolio-300318.appspot.com/o/logo.png?alt=media&token=6c587bd0-e754-4a78-b26e-f355aa39d89b'
  isNavbarDisplayed = false

  toggleNavbar(state?: boolean) {
    if (window.innerWidth < 768) {
      if (typeof state !== 'undefined') {
        this.isNavbarDisplayed = state
      } else {
        this.isNavbarDisplayed = !this.isNavbarDisplayed
      }
    }
  }

  get user() {
    return vxm.user.username
  }
}
