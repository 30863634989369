















import { Component, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store/store.vuex'

@Component
export default class LanguageSwitcher extends Vue {
  language = this.currentLanguage

  @Watch('language')
  setLocale() {
    this.$store.dispatch('app/setLanguage', this.language)
  }

  get currentLanguage() {
    return vxm.app.getLanguage
  }
}
