






































import { Component, Vue } from 'vue-property-decorator'
import Navbar from './Navbar.vue'
import { vxm } from '@/store/store.vuex'

@Component({
  components: {
    Navbar
  }
})
export default class Hero extends Vue {
  stories: HTMLElement | null = null
  arrayStory: NodeListOf<HTMLElement> = document.querySelectorAll('.story')
  isDisplayingArrows = true
  index = 0
  autoScroll = setInterval(() => {
    this.navigateStories('next', true)
  }, 10000)

  scrollListener = document.addEventListener('wheel', () => {
    clearInterval(this.autoScroll)
  })

  created() {
    this.$store.dispatch('firestore/getHomepagePhotos')
  }
  mounted() {
    setTimeout(() => {
      this.stories = this.$el.querySelector('.stories')
      this.arrayStory = this.$el.querySelectorAll('.story')
    }, 1000)
  }

  click($event: MouseEvent) {
    if (this.stories instanceof HTMLElement) {
      clearInterval(this.autoScroll)
      const median = window.innerWidth / 2
      this.navigateStories($event.clientX > median ? 'next' : 'prev')
    }
  }

  navigateStories(direction: string, force = false) {
    this.isDisplayingArrows = false
    if (typeof this.arrayStory[this.index] === 'undefined') {
      clearInterval(this.autoScroll)
      return
    }
    if (direction === 'next') {
      if (this.index < this.arrayStory.length - 1) {
        this.index++
      } else {
        if (force) this.index = 0
      }
      this.arrayStory[this.index].scrollIntoView({ behavior: 'smooth' })
    } else if (direction === 'prev') {
      if (this.index > 0) {
        this.index--
      } else {
        if (force) this.index = this.arrayStory.length - 1
      }
      this.arrayStory[this.index].scrollIntoView({ behavior: 'smooth' })
    }
  }

  get homepagePhotos() {
    return vxm.firestore.homepagePhotos
  }
}
